//
//
//
//
//

import { initWx } from '../components/minxin'
import mobileTableList from './components/list.vue'
export default {
    components: {
        mobileTableList,
    },
    mixins: [initWx],
    data() {
        return {
            approvalTab: 0,
            tabType: null,
        }
    },
    mounted() {
        const { index } = this.$route.query
        this.approvalTab = index
        this.tabType = index
    },
}
